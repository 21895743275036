import MaterialTable from "material-table";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { apiReleaseNoteGet, apiReleaseNotesGetList } from "../../api/apiReleaseNotes";
import { CurrentSiteIdGet } from "../../api/userSettings";
import { UserPreferencesContext } from "../../context/userPreferences";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import Loader from "../common/Loader";
import PageTitle from "../common/PageTitle";

const ReleaseNotes = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { savePreference, prefsObj, prefsLoaded } = useContext(UserPreferencesContext);
	const [selectedTab, setSelectedTab] = useState(0);
	const [modalData, setModalData] = useState(undefined);

	const LoadData = useCallback(async () => {
		setLoading(true);
		const siteId = CurrentSiteIdGet();
		const result = await apiReleaseNotesGetList(siteId);
		setData(result);
		setLoading(false);
	}, []);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	useEffect(() => {
		if (!prefsLoaded) {
			return;
		}

		if (data.length === 0) {
			return;
		}

		const date = data.reduce((p, c) => (p.date > c.date ? p : c), "")?.date;

		if (date && date > (prefsObj.LastReleaseNote || "")) {
			savePreference("Last_ReleaseNote", date);
		}
	}, [data, savePreference, prefsObj, prefsLoaded]);

	const columns = [{ field: "date", title: local.TS_Date, defaultSort: "desc", render: (rowData) => <div>{moment(rowData.date).format("DD MMM YYYY")}</div> }, { field: "name", title: local.TS_Name }];

	function download(filename, text) {
		var element = document.createElement("a");
		element.setAttribute("href", "data:application/octet-stream;charset=utf-16le;base64," + text);
		element.setAttribute("download", filename);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	const rowClick = async (_e, rowData) => {
		const siteId = CurrentSiteIdGet();
		const file = await apiReleaseNoteGet(siteId, rowData.filename);
		if (rowData.commitLog) {
			setModalData({ title: rowData.name, file });
		} else {
			download(rowData.filename, file);
		}
	};

	const tabNavItem = (index, title) => {
		return (
			<NavItem>
				<NavLink
					className={selectedTab === index ? "active" : ""}
					onClick={() => {
						setSelectedTab(index);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	return (
		<>
			<PageTitle title={local.TF_Documentation} />
			<Modal isOpen={!!modalData} toggle={() => setModalData(undefined)} centered backdrop="static">
				<ModalHeader>{modalData?.title}</ModalHeader>
				<ModalBody>
					<p style={{ whiteSpace: "pre-line" }}>{modalData?.file}</p>
					<Button size="sm" color="secondary" onClick={() => setModalData(undefined)}>
						{local.TS_Close}
					</Button>
				</ModalBody>
			</Modal>
			{loading && <Loader />}
			{!loading && (
				<>
					<Nav tabs>
						{tabNavItem(0, local.TF_Manuals)}
						{tabNavItem(1, local.TF_Release_Notes)}
					</Nav>
					<TabContent activeTab={selectedTab}>
						<TabPane tabId={0}>
							<MaterialTable onRowClick={rowClick} columns={columns} data={data.filter((x) => !x.commitLog)} title="" icons={tableIcons} options={{ headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
						</TabPane>
						<TabPane tabId={1}>
							<MaterialTable onRowClick={rowClick} columns={columns} data={data.filter((x) => x.commitLog)} title="" icons={tableIcons} options={{ headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
						</TabPane>
					</TabContent>
				</>
			)}
		</>
	);
};

export default ReleaseNotes;
