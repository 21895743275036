import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState } from "react";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { UserPreferencesContext } from "../../context/userPreferences";
import local from "../../localization/strings";

const buttonDefaultClasses = "mr-2 btn-shadow btn-sm mb-sm-2 px-3 py-2 px-md-2 py-md-1 button-font";

const ChartToolbarMain = (props) => {
	const { mode, action, isMaximised, addingNote } = props;
	const [timescalesOpen, setTimescalesOpen] = useState(false);
	const [notesOpen, setNotesOpen] = useState(false);
	const { prefsObj } = useContext(UserPreferencesContext);

	function renderMode() {
		switch (mode) {
			case "Chart":
				return addingNote ? (
					<Button className={buttonDefaultClasses} color="primary" transform="shrink-3" onClick={() => action("CancelAddNote")}>
						<FontAwesomeIcon icon="sticky-note" />
						<span className="d-none d-md-inline-block ml-2">{local.TS_Cancel}</span>
					</Button>
				) : (
					<Fragment>
						<Dropdown className="button-font" group isOpen={timescalesOpen} toggle={() => setTimescalesOpen(!timescalesOpen)} direction="right">
							<DropdownToggle caret className={buttonDefaultClasses} color="info" transform="shrink-3">
								<FontAwesomeIcon icon="ruler-combined" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_View}</span>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem header>{local.TF_TimeScales}</DropdownItem>
								<DropdownItem onClick={() => action("Day")}>
									<FontAwesomeIcon icon={props.period === "Day" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_Day}
								</DropdownItem>
								<DropdownItem onClick={() => action("Week")}>
									<FontAwesomeIcon icon={props.period === "Week" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_Week}
								</DropdownItem>
								<DropdownItem onClick={() => action("Month")}>
									<FontAwesomeIcon icon={props.period === "Month" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_Month}
								</DropdownItem>
								<DropdownItem onClick={() => action("Year")}>
									<FontAwesomeIcon icon={props.period === "Year" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_Year}
								</DropdownItem>
								<DropdownItem onClick={() => action("ShowPeriod")}>
									<FontAwesomeIcon icon={props.period === "Custom" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Custom}
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem header>{local.TF_Apply_Timescale_Changes_To}</DropdownItem>
								<DropdownItem onClick={() => action("SetApplyPeriodChangesTo_Chart")}>
									<FontAwesomeIcon icon={props.applyPeriodChangesTo === "Chart" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Chart}
								</DropdownItem>
								<DropdownItem onClick={() => action("SetApplyPeriodChangesTo_Zone")}>
									<FontAwesomeIcon icon={props.applyPeriodChangesTo === "Zone" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_Zone}
								</DropdownItem>
								<DropdownItem onClick={() => action("SetApplyPeriodChangesTo_Site")}>
									<FontAwesomeIcon icon={props.applyPeriodChangesTo === "Site" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Site}
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem header>{local.TF_YAxis_Settings}</DropdownItem>
								<DropdownItem onClick={() => action("ShowRanges")}>{local.TF_Custom}</DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => action("ToggleStripLine")}>
									<FontAwesomeIcon icon={props.showThresholds ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Limits}
								</DropdownItem>
								<DropdownItem onClick={() => props.toggleTooltip()}>
									<FontAwesomeIcon icon={props.showTooltips ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Toolips}
								</DropdownItem>
								<DropdownItem onClick={() => action("ToggleNotes")}>
									<FontAwesomeIcon icon={props.showNotes ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Notes}
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => action("ResetThisChart")}>{local.TS_ResetChart}</DropdownItem>
								<DropdownItem onClick={() => action("Reset")}>{local.TS_ResetAllCharts}</DropdownItem>
								<DropdownItem onClick={() => action("ToggleResetOnLoad")}>
									<FontAwesomeIcon icon={props.resetOnLoad ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TS_ResetOnExit}
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>

						<Button className={buttonDefaultClasses} color="dark" transform="shrink-3" onClick={() => action("ShowCustomThresholds")}>
							<FontAwesomeIcon icon="bars" />
							<span className="d-none d-md-inline-block ml-2">{local.TS_Thresholds}</span>
						</Button>

						<Button className={buttonDefaultClasses} color="success" transform="shrink-3" onClick={() => action("ShowOverlay")}>
							<FontAwesomeIcon icon="plus" />
							<span className="d-none d-md-inline-block ml-2">{local.TF_Overlays}</span>
						</Button>

						<Button className={buttonDefaultClasses} color="danger" transform="shrink-3" onClick={() => action("ShowCompare")}>
							<FontAwesomeIcon icon="sliders-h-square" />
							<span className="d-none d-md-inline-block ml-2">{local.TF_Compare}</span>
						</Button>

						<Button className={buttonDefaultClasses} color="warning" transform="shrink-3" onClick={() => action("ShowSummary")}>
							<FontAwesomeIcon icon="list-ol" />
							<span className="d-none d-md-inline-block ml-2">{local.TF_Summary}</span>
						</Button>

						<Dropdown group isOpen={notesOpen} toggle={() => setNotesOpen(!notesOpen)}>
							<DropdownToggle caret className={buttonDefaultClasses} color="primary" transform="shrink-3">
								<FontAwesomeIcon icon="sticky-note" />
								<span className="d-none d-md-inline-block ml-2">{local.TF_Notes}</span>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => action("AddNote")}>{local.TF_Add_a_note}</DropdownItem>
								<DropdownItem tag="a" href="/charts/notes">
									{local.TF_View_and_edit_notes}
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => action("SmallNotes")}>
									<FontAwesomeIcon icon={prefsObj?.ChartNoteSize === "Small" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Small_Notes}
								</DropdownItem>
								<DropdownItem onClick={() => action("MediumNotes")}>
									<FontAwesomeIcon icon={(prefsObj?.ChartNoteSize || "Medium") === "Medium" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Medium_Notes}
								</DropdownItem>
								<DropdownItem onClick={() => action("LargeNotes")}>
									<FontAwesomeIcon icon={prefsObj?.ChartNoteSize === "Large" ? faCheckSquare : faSquare} className="mr-1" size="lg" fixedWidth />
									{local.TF_Large_Notes}
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Fragment>
				);

			case "CustomThresholds":
				return (
					<Button className={buttonDefaultClasses} color="info" transform="shrink-3" onClick={() => action("HideCustomThresholds")}>
						<FontAwesomeIcon icon="chart-line" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Chart}</span>
					</Button>
				);

			case "Overlay":
				return (
					<Button className={buttonDefaultClasses} color="success" transform="shrink-3" onClick={() => action("HideOverlay")}>
						<FontAwesomeIcon icon="chart-line" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Chart}</span>
					</Button>
				);

			case "Compare":
				return (
					<Button className={buttonDefaultClasses} color="danger" transform="shrink-3" onClick={() => action("HideCompare")}>
						<FontAwesomeIcon icon="chart-line" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Chart}</span>
					</Button>
				);

			case "Notes":
				return (
					<Button className={buttonDefaultClasses} color="success" transform="shrink-3" onClick={() => action("HideOverlay")}>
						<FontAwesomeIcon icon="chart-line" />
						<span className="d-none d-md-inline-block ml-2">{local.TF_Chart}</span>
					</Button>
				);

			default:
				return "";
		}
	}

	return (
		<Row id="chartOptions" className="my-2 justify-content-center">
			<Col className="col-auto">
				{renderMode()}
				{!addingNote && (
					<Fragment>
						{isMaximised ? (
							<Button className={buttonDefaultClasses} color="secondary" transform="shrink-3" onClick={() => action("Minimise")}>
								<FontAwesomeIcon icon="window-minimize" />
								<span className="d-none d-md-inline-block ml-2">{local.TF_Minimise}</span>
							</Button>
						) : (
							<Button className={buttonDefaultClasses} color="secondary" transform="shrink-3" onClick={() => action("Maximise")}>
								<FontAwesomeIcon icon="window-maximize" />
								<span className="d-none d-md-inline-block ml-2">{local.TF_Maximise}</span>
							</Button>
						)}
					</Fragment>
				)}
			</Col>
		</Row>
	);
};

export default ChartToolbarMain;
